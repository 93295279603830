import { convertDataToHtml } from 'commonComponents/richText';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  FormKeys as ServicesFormKeys,
  getCurrentDate,
  getDefaultServicesData,
  getServicesData,
  isTimeSameOrBefore,
  ServicesTimeError,
  serviceTypes,
  slugs,
  visitTypes,
} from 'src/app/components/salesComponents/contractCreation/addServices/helper';
import { MONTHLY_RATE as ON_DEMAND_MONTHLY_RATE } from 'src/app/components/salesComponents/contractCreation/onDemandServices/systemService';
import {
  FormKeys,
  FormKeys as PaymentTermsFormKeys,
  PlanTypeEnums,
} from 'src/app/components/salesComponents/contractCreation/paymentTerms/helper';
import {
  getDateDifference,
  isObjectEmpty,
  removeKeysFromObject,
} from 'src/helper/utilityFunctions';
import { SelectedDateTpeContract, stageStatus } from 'src/utils/constants';
import { convertMMDDYYYYToDayJsDate, formatDayJsDate } from 'src/utils/passTime/time';

export const ActiveStepsKeys = {
  SERVICES: 'services',
  DEVICES: 'devices',
  ON_DEMAND_SERVICES: 'onDemandServices',
  PAYMENT_TERMS: 'paymentTerms',
  DESCRIPTIONS: 'descriptions',
  CONFIGURATION: 'configuration',
};

export const ActiveSteps = [...Object.values(ActiveStepsKeys)];

export const defaultSteps = [
  {
    name: 'Services',
    subtext: 'Add services of this proposal',
    value: ActiveStepsKeys.SERVICES,
    status: stageStatus.CURRENT,
  },
  {
    name: 'Devices',
    subtext: 'Choose devices for checkpoints',
    value: ActiveStepsKeys.DEVICES,
    status: stageStatus.PENDING,
  },
  {
    name: 'On Demand',
    subtext: 'Add services of this proposal',
    value: ActiveStepsKeys.ON_DEMAND_SERVICES,
    status: stageStatus.PENDING,
  },
  {
    name: 'Payment Terms',
    subtext: 'Set payment preferences',
    value: ActiveStepsKeys.PAYMENT_TERMS,
    status: stageStatus.PENDING,
  },
  {
    name: 'Description',
    subtext: 'Terms & conditions for proposals',
    value: ActiveStepsKeys.DESCRIPTIONS,
    status: stageStatus.PENDING,
  },
  {
    name: 'Signees',
    subtext: 'Add/choose signees for contract',
    value: ActiveStepsKeys.CONFIGURATION,
    status: stageStatus.PENDING,
  },
];

const getServicesPayload = (formData) => {
  return {
    name: formData.name,
    timezone: formData.timezone?.id,
    startDate: formatDayJsDate(formData.startDate, 'date'),
    endDate: formatDayJsDate(formData.endDate, 'date'),
    [ActiveStepsKeys.SERVICES]: formData[ActiveStepsKeys.SERVICES].map((service) => ({
      ...service,
      officerType: service.officerType?.id,
      lineItem: service?.lineItem?.value,
      instructions: convertDataToHtml(service?.instructions),
      visits: service.visits.map((visit) => ({
        ...visit,
        startTime: formatDayJsDate(visit?.startTime, 'time'),
        endTime: formatDayJsDate(visit?.endTime, 'time'),
      })),
    })),
  };
};

const getDevicesPayload = (formData) => {
  return {
    [ActiveStepsKeys.DEVICES]: formData.map((device) => ({ ...device, price: device.price || 0 })),
  };
};

const getPaymentTermsPayload = (data) => {
  const payload = {
    ...data,
    planId: Number(data.plan),
    paymentMethodId: Number(data.paymentMethod.value),
    paymentDateId: Number(data.paymentDate.value),
    billingTypeId: Number(data?.billingType.value),
    contractType: Number(data?.contractType?.value),
    fuelSurcharge: data.fuelSurcharge ? Number(data.fuelSurcharge) : null,
    taxRate: data.taxRate ? Number(data.taxRate) : null,
    flatRate: Number(data.flatRate),
    hasDifferentBillingAddress: data.hasDifferentBillingAddress,
    billingFrequency: Number(data.billingFrequency.value),
    address: {
      streetAddress: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      countryCode: data.countryCode,
      postalCode: data.postalCode,
    },
  };

  if (data.plan !== PlanTypeEnums.FLAT) delete payload.flatRate;

  if (data.plan === PlanTypeEnums.FLAT) payload.fuelSurcharge = 0;

  delete payload.billingType;
  delete payload.plan;
  delete payload.paymentMethod;
  delete payload.paymentDate;

  return { [ActiveStepsKeys.PAYMENT_TERMS]: payload };
};

const getOnDemandServicesPayload = (formData) => {
  return {
    [ActiveStepsKeys.ON_DEMAND_SERVICES]: formData.map((onDemandService) => ({
      ...onDemandService,
      price: Number(onDemandService.price),
      quantity: Number(onDemandService.quantity),
    })),
  };
};

const getDescriptionsPayload = (formData) => {
  return {
    [ActiveStepsKeys.DESCRIPTIONS]: { services: convertDataToHtml(formData?.services) },
  };
};

const getConfigurationPayload = (formData) => {
  return { [ActiveStepsKeys.CONFIGURATION]: [...formData[ActiveStepsKeys.CONFIGURATION]] };
};

export const getPayload = (formData, key, apiData) => {
  let payload = {};
  switch (key) {
    case ActiveStepsKeys.SERVICES:
      payload = getServicesPayload(formData);
      break;
    case ActiveStepsKeys.DEVICES:
      payload = getDevicesPayload(formData);
      break;
    case ActiveStepsKeys.PAYMENT_TERMS:
      payload = getPaymentTermsPayload(formData);
      break;
    case ActiveStepsKeys.ON_DEMAND_SERVICES:
      payload = getOnDemandServicesPayload(formData, apiData);
      break;
    case ActiveStepsKeys.DESCRIPTIONS:
      payload = getDescriptionsPayload(formData);
      break;
    case ActiveStepsKeys.CONFIGURATION:
      payload = getConfigurationPayload(formData);
      break;
  }
  return payload;
};

export const getServicesApiData = (apiData, baseRates, lineItems) => {
  const details = apiData?.details;

  const doesServicesExist = apiData?.[ActiveStepsKeys.SERVICES]?.length;

  const selectedDateType =
    details?.selectedDateType === SelectedDateTpeContract.oneTime ? 'endDate' : 'renewalDate';

  const startEndDates = details?.startDate
    ? {
        startDate: convertMMDDYYYYToDayJsDate(details?.startDate),
        endDate: convertMMDDYYYYToDayJsDate(details?.endDate),
      }
    : {};

  const actualContractDates =
    details?.actualContractDates && details?.actualContractDates?.start
      ? {
          startDate: convertMMDDYYYYToDayJsDate(
            dayjs(details?.actualContractDates?.start).format('YYYY/MM/DD'),
          ),
          endDate: convertMMDDYYYYToDayJsDate(
            dayjs(details?.actualContractDates?.end).format('YYYY/MM/DD'),
          ),
        }
      : {};

  const apiTimezone = apiData?.details?.timezone;
  const timezone = apiTimezone?.id ? { value: apiTimezone.id, label: apiTimezone.name } : null;

  if (!doesServicesExist)
    return {
      ...startEndDates,
      timezone,
      [ActiveStepsKeys.SERVICES]: [getDefaultServicesData(0)],
      selectedDateType,
      renewalReminderDays: selectedDateType === 'renewalDate' ? details?.renewalReminderDays : null,
      autoRenewal: selectedDateType === 'renewalDate' ? details?.autoRenewal : null,
      type: details?.type,
      actualContractDates,
    };

  const services = apiData?.[ActiveStepsKeys.SERVICES];

  return {
    ...(details?.startDate
      ? {
          startDate: convertMMDDYYYYToDayJsDate(details?.startDate),
          endDate: convertMMDDYYYYToDayJsDate(details?.endDate),
        }
      : {}),
    timezone,
    [ActiveStepsKeys.SERVICES]: getServicesData(services, baseRates, lineItems),
    selectedDateType,
    renewalReminderDays: selectedDateType === 'renewalDate' ? details.renewalReminderDays : null,
    autoRenewal: selectedDateType === 'renewalDate' ? details?.autoRenewal : null,
    type: details?.type,
    actualContractDates,
  };
};

const getConfigurationEditData = (formData, details) => {
  const { phoneNumber, fax, email, licenseNumber } = details;
  return {
    phoneNumber,
    fax,
    email,
    licenseNumber,
    [ActiveStepsKeys.CONFIGURATION]: formData,
  };
};

export const getEditData = (formData, key, apiData) => {
  const details = apiData?.details;

  if (key === ActiveStepsKeys.CONFIGURATION) return getConfigurationEditData(formData, details);

  return formData;
};

const emptyStateBaseRates = {
  [slugs.ARMED_OFFICER]: 0,
  [slugs.PATROL_OFFICER]: 0,
  [slugs.DEDICATED_OFFICER]: 0,
  [slugs.VISITOR_MANAGEMENT]: 0,
  [slugs.LOAD_MANAGEMENT]: 0,
};

export const useBaseRates = (preferences) => {
  const [baseRates, setBaseRates] = useState({ ...emptyStateBaseRates });
  useEffect(() => {
    if (preferences) calculateBaseRates();
  }, [preferences]);

  const calculateBaseRates = () => {
    let newBaseRates = {
      ...emptyStateBaseRates,
    };
    preferences?.generalServices?.forEach((preference) => {
      newBaseRates[preference.slug] = preference.rateValue;
    });
    preferences?.extraServices?.forEach((preference) => {
      newBaseRates[preference.slug] = preference.rateValue;
    });

    setBaseRates({ ...baseRates, ...newBaseRates });
  };

  return baseRates;
};

const getValidateServices = (formData, apiData, baseRates) => {
  const shouldValidateDates = formData?.startDate || formData?.endDate;

  return {
    timezone: formData.timezone,
    ...(shouldValidateDates
      ? {
          startDate: formatDayJsDate(formData?.startDate, 'date'),
          endDate: formatDayJsDate(formData?.endDate, 'date'),
        }
      : {}),
    [ActiveStepsKeys.SERVICES]: formData[ActiveStepsKeys.SERVICES].map((service) => {
      const visits = service.visits.map((visit) => {
        const updatedVisit = {
          ...visit,
          startTime: formatDayJsDate(visit.startTime || null, 'time'),
          endTime: formatDayJsDate(visit.endTime || null, 'time'),
        };

        if (updatedVisit.visitType === visitTypes.FIXED) delete updatedVisit.endTime;

        return updatedVisit;
      });

      return {
        ...service,
        officerType: !isObjectEmpty(service.officerType)
          ? { ...service.officerType, rate: baseRates?.[service.officerType.value] }
          : null,
        lineItem: service?.lineItem?.value || null,
        visits,
      };
    }),
  };
};

const getValidateConfiguration = ({ phoneNumber, email, fax, licenseNumber }) => {
  return {
    ...(phoneNumber && { phoneNumber }),
    ...(email && { email }),
    ...(fax && { fax }),
    ...(licenseNumber && { licenseNumber }),
  };
};

const getValidatePaymentTerms = (formData) => {
  let paymentTerms = {
    ...formData,
    paymentMethod:
      formData?.paymentMethod?.value != null ? JSON.stringify(formData.paymentMethod.value) : '',
    paymentDate:
      formData?.paymentDate?.value != null
        ? JSON.stringify(Number(formData.paymentDate.value))
        : '',
    billingType:
      formData?.billingType?.value != null ? JSON.stringify(formData.billingType.value) : '',
    contractType:
      formData?.contractType?.value != null ? JSON.stringify(formData.contractType.value) : '',
  };

  if (paymentTerms.plan !== PlanTypeEnums.FLAT)
    paymentTerms = removeKeysFromObject(paymentTerms, ['flatRate']);

  if (paymentTerms.plan === PlanTypeEnums.FLAT)
    paymentTerms = removeKeysFromObject(paymentTerms, ['fuelSurcharge']);

  if (!paymentTerms.hasDifferentBillingAddress)
    paymentTerms = removeKeysFromObject(paymentTerms, [
      FormKeys.ADDRESS,
      FormKeys.CITY,
      FormKeys.STATE,
      FormKeys.COUNTRY,
      FormKeys.COUNTRY_CODE,
      FormKeys.POSTAL_CODE,
    ]);

  return {
    [ActiveStepsKeys.PAYMENT_TERMS]: paymentTerms,
  };
};

export const getValidateFormData = (formData, activeTabKey, apiData) => {
  if (activeTabKey === ActiveStepsKeys.SERVICES)
    return { ...getValidateServices(formData, apiData) };
  if (activeTabKey === ActiveStepsKeys.CONFIGURATION) return getValidateConfiguration(formData);
  if (activeTabKey === ActiveStepsKeys.PAYMENT_TERMS) return getValidatePaymentTerms(formData);

  return { [activeTabKey]: formData };
};

export const getViewDisabledContractClass = (isPublished) =>
  isPublished ? 'viewDisabledContract' : '';

const formatMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (hours === 0) {
    return mins === 1 ? `${mins} min` : `${mins} mins`;
  } else if (mins === 0) {
    return hours === 1 ? `${hours} hour` : `${hours} hours`;
  } else {
    const hourString = hours === 1 ? `${hours} hour` : `${hours} hours`;
    const minString = mins === 1 ? `${mins} min` : `${mins} mins`;
    return `${hourString} and ${minString}`;
  }
};

export const getServicesTimeFieldErrors = (services) => {
  const errors = {};
  const currentDate = getCurrentDate();

  services.forEach((service, serviceIndex) => {
    service.visits.forEach((visit, visitIndex) => {
      const sTime = visit[ServicesFormKeys.START_TIME];
      const eTime = visit[ServicesFormKeys.END_TIME];

      // Convert start and end times to dayjs objects
      const start = currentDate.set('hour', sTime?.hour()).set('minute', sTime?.minute());
      let end = currentDate.set('hour', eTime?.hour()).set('minute', eTime?.minute());

      if (isTimeSameOrBefore(end, start)) end = end.add('1', 'd');

      const minutesDiff = end.diff(start, 'minute');

      if (
        service[ServicesFormKeys.TYPE] === serviceTypes.PATROL &&
        visit[ServicesFormKeys.VISIT_TYPE] === visitTypes.FIXED
      ) {
        return;
      }

      if (service[ServicesFormKeys.TYPE] === serviceTypes.DEDICATED && minutesDiff < 30) {
        errors[
          `${ActiveStepsKeys.SERVICES},${serviceIndex},${ServicesFormKeys.VISITS},${visitIndex},${ServicesTimeError}`
        ] = 'Start time and end time duration should be at least 30 mins';
        return;
      }

      const minVisitTimeRange = Number(visit[ServicesFormKeys.NUMBER_OF_VISITS]) * 15;

      if (
        service[ServicesFormKeys.TYPE] === serviceTypes.PATROL &&
        minutesDiff < minVisitTimeRange
      ) {
        errors[
          `${ActiveStepsKeys.SERVICES},${serviceIndex},${ServicesFormKeys.VISITS},${visitIndex},${ServicesTimeError}`
        ] =
          `Start time and end time duration should be at least ${formatMinutes(minVisitTimeRange)}`;
        return;
      }
    });
  });
  return Object.keys(errors).length ? errors : null;
};

export const getErrorKey = ({ activeStep, index, key }) => {
  return `${activeStep},${index},${key}`;
};

export const showError = ({ activeStep, index, key, errorMessages }) => {
  return errorMessages?.[`${getErrorKey({ activeStep, index, key })}`];
};
export const getNestedErrorKey = ({ activeStep, index, nestedFormDataKey, nestedIndex, key }) => {
  return `${activeStep},${index},${nestedFormDataKey},${nestedIndex},${key}`;
};

export const showNestedError = ({
  activeStep,
  index,
  nestedFormDataKey,
  nestedIndex,
  key,
  errorMessages,
}) => {
  return errorMessages?.[
    `${getNestedErrorKey({ activeStep, index, nestedFormDataKey, nestedIndex, key })}`
  ];
};

export const getPlanId = (apiData, activeStep, formData) => {
  /**
   * If StartDate does not exist that means dates are to be decided later
   * so we create a default plan of weekly payments
   * */
  const daysDifference = apiData?.details?.startDate
    ? getDateDifference(apiData?.details?.startDate, apiData?.details?.endDate)
    : 7;
  if (ActiveSteps[activeStep] === ActiveStepsKeys.PAYMENT_TERMS) {
    return formData?.plan?.id?.toString()
      ? formData?.plan
      : daysDifference < 7
        ? PlanTypeEnums.EVENT
        : PlanTypeEnums.WEEKLY || PlanTypeEnums.WEEKLY;
  }
  let apiPlanId = apiData?.details?.plan;
  apiPlanId = Number.isInteger(apiPlanId) ? apiPlanId.toString() : apiPlanId;

  if (!apiPlanId) {
    return daysDifference < 7 ? PlanTypeEnums.EVENT : PlanTypeEnums.WEEKLY;
  }

  return apiPlanId;
};

export const getOnDemandTotal = (onDemandServices) => {
  if (!onDemandServices) return 0;
  return onDemandServices?.reduce((acc, service) => {
    if (service.rate === ON_DEMAND_MONTHLY_RATE && !service._destroy) {
      return acc + Number(service.price) * Number(service.quantity);
    }
    return acc;
  }, 0);
};

export const PlanMultiplier = {
  [PlanTypeEnums.MONTHLY]: 4.35,
  [PlanTypeEnums.BI_WEEKLY]: 2,
  [PlanTypeEnums.WEEKLY]: 1,
  [PlanTypeEnums.EVENT]: 1,
  [PlanTypeEnums.FLAT]: 1,
};

export const PlanDivider = {
  [PlanTypeEnums.MONTHLY]: 1,
  [PlanTypeEnums.BI_WEEKLY]: 2,
  [PlanTypeEnums.WEEKLY]: 4.35,
  [PlanTypeEnums.EVENT]: 1,
  [PlanTypeEnums.FLAT]: 1,
};

export const getContractCalculations = ({
  paymentTerms,
  services,
  servicesTotal,
  onDemandTotal,
}) => {
  const taxRate = paymentTerms?.[PaymentTermsFormKeys.TAX_RATE];

  const flatRate = paymentTerms?.[PaymentTermsFormKeys.FLAT_RATE];

  const fuelSurcharge = paymentTerms?.[PaymentTermsFormKeys.FUEL_SURCHARGE];

  const calculations = {};

  // Calculate Fuel Surcharge amount based on flag in each service
  Object.values(PlanTypeEnums).forEach((planTypeValue) => {
    let fuelSurchargeAmount = 0;
    if (fuelSurcharge)
      services?.forEach((service) => {
        if (service.addFuelSurcharge) {
          fuelSurchargeAmount +=
            (Number(fuelSurcharge) / 100) *
            service.calculations.total *
            PlanMultiplier[planTypeValue];
        }
      });

    // servicesTotal is Weekly so multiply with planTypeValue
    const servicesPlanTotal = servicesTotal * PlanMultiplier[planTypeValue];

    // onDemandToal is Monthly so divide with planTypeValue
    const onDemandPlanTotal = onDemandTotal / PlanDivider[planTypeValue];

    let totalAfterFuelSurcharge = servicesPlanTotal + onDemandPlanTotal + fuelSurchargeAmount;
    if (planTypeValue === PlanTypeEnums.FLAT) totalAfterFuelSurcharge = Number(flatRate);

    const taxAmount = Number(taxRate) ? (Number(taxRate) / 100) * totalAfterFuelSurcharge : 0;

    const total = totalAfterFuelSurcharge + taxAmount;

    calculations[planTypeValue] = {
      fuelSurchargeAmount,
      taxAmount,
      total,
    };
  });

  return calculations;
};

export const getTotalPrice = ({ planId, contractCalculations }) => {
  planId = Number.isInteger(planId) ? planId.toString() : planId;

  return Number(contractCalculations?.[planId]?.total || 0);
};
